<template>
    <div>
        <form class="authentication-card">
            <h1 class="authentication-heading" v-html="resource[lang].register_via_teem" />
            <AuthenticationInputText required
                class="authentication-input" 
                v-model="email" 
                :placeholder="resource[lang].email_address"
            />
            <AuthenticationInputText required password
                class="authentication-input" 
                v-model="password" 
                :placeholder="resource[lang].password" 
            />
            <Button stretch rounded submit
                class="authentication-button" 
                color="accent" 
                :label="resource[lang].register_button" 
                @click="register"
            />
        </form>
        
        <AuthenticationLink link="/register" :label="resource[lang].create_new_account" />
    </div>
</template>

<script>
import resource from '@/assets/resources/register-with-teem-resource'

import AuthenticationInputText from '@/components/basic/AuthenticationInputText'
import AuthenticationLink from '@/components/basic/AuthenticationLink'
import Button from '@/components/basic/Button'

export default {
    components: {
        AuthenticationInputText,
        AuthenticationLink,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

       return {
           lang: lang,

           email: '',
           password: '',

           resource
       } 
    },
    methods: {
        // Request
        registerRequest() {

        },

        // Action
        register() {
            // validate form
            // execute request
            this.$router.push('/registration-confirmation');
        }
    }
}
</script>