const resource = {
    en: {
        register_via_teem: 'Register via TEEM',
        email_address: 'Email Address',
        password: 'Password',
        register_button: 'Register',
        create_new_account: 'Create new Account?',
    },
    de: {
        register_via_teem: 'Registrierung vie TEEM',
        email_address: 'Email Adresse',
        password: 'Passwort',
        register_button: 'Registrieren',
        create_new_account: 'Neuen Account erstellen?'
    }
}

export default resource;