<template>
    <div>
        <router-link :to="link" v-html="label" />
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        link: String,
    }
}
</script>

<style lang="scss" scoped>

a {
    color: var(--primary);

    font-family: var(--standard-font);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;

    text-align: center;
    text-decoration: underline;
}

a:hover {
    color: var(--blak);
}

div {
    margin-block-start: 1.5rem;
    text-align: center;
}

</style>